import React from "react"

import "./styles.css"

const HeroSection = () => {
  return (
    <>
      <div id="sketchBg"></div>
      <div id="sketchLogo"></div>
      <div id="toggleMode">
        <span>Retrato</span>
        <svg
          height="30px"
          width="30px"
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 100 100"
        >
          <g>
            <path d="M51.203,39.533c-6.251,0-11.316,5.066-11.316,11.316s5.064,11.316,11.316,11.316c6.25,0,11.317-5.066,11.317-11.316   S57.453,39.533,51.203,39.533z"></path>
            <path d="M72.987,31H65.36c-0.457-3-2.357-4-4.635-4H39.37c-2.276,0-4.178,1-4.633,4h-7.363C24.205,31,21,33.38,21,36.548v28.714   C21,68.432,24.205,71,27.374,71h45.613C76.156,71,78,68.432,78,65.262V36.548C78,33.38,76.156,31,72.987,31z M28.892,41.667   c-1.87,0-3.385-1.516-3.385-3.385c0-1.872,1.515-3.386,3.385-3.386c1.87,0,3.386,1.515,3.386,3.386   C32.278,40.151,30.762,41.667,28.892,41.667z M51.201,65.225c-7.939,0-14.374-6.209-14.374-14.147   C36.828,43.606,42.523,37,49.811,37h2.784c7.286,0,12.982,6.606,12.982,14.077C65.577,59.016,59.141,65.225,51.201,65.225z"></path>
          </g>
        </svg>
      </div>
    </>
  )
}

export default HeroSection
